const body = document.body;
const footer = document.querySelector('footer');
const mainContent = document.querySelector('.main-content');
const scrollableElement = document.getElementById("main");
const animDuration = 500;
const animDelay = 0;

let scrolled = false;
let freezeScroll;

function checkWidth() {
  freezeScroll = false;
  let wWidth = window.innerWidth;
  if (wWidth > 600) {
    freezeScroll = true;
  } else {
    freezeScroll = false;
  }
}

function footerReveal() {
  let footerHeight = footer.offsetHeight;
  mainContent.style.marginBottom = footerHeight + 'px';
}

function Scrolling() {
  if (!scrolled) {
    body.classList.add("scrolled");
    scrolled = true;
    setTimeout(function () { freezeScroll = false; }, animDuration);
  }
  if (freezeScroll) {
    scrollableElement.scrollTop = 1;
  }
}

function noScrolling() {
  setTimeout(function () {
    body.classList.remove("scrolled");
    scrolled = false;
    checkWidth();
  }, animDelay);

}

scrollableElement.addEventListener('scroll', function () {
  let scrollY = scrollableElement.scrollTop;
  if (scrollY > 0) {
    Scrolling();
  } else {
    noScrolling();
  }
});


(function loadAnimations() {
  bodymovin.loadAnimation({
    container: document.getElementById('qooa-ombrello-animation'),
    path: 'QooA_Ombrello.json',
    renderer: 'svg',
    loop: true,
    autoplay: true,
    name: "QooA Ombrello"
  });
})();

class Accordion {
  constructor(domNode) {
    this.rootEl = domNode;
    this.buttonEl = this.rootEl.querySelector('button[aria-expanded]');

    const controlsId = this.buttonEl.getAttribute('aria-controls');
    this.contentEl = document.getElementById(controlsId);

    this.open = this.buttonEl.getAttribute('aria-expanded') === 'true';

    // add event listeners
    this.buttonEl.addEventListener('click', this.onButtonClick.bind(this));
  }

  onButtonClick() {
    this.toggle(!this.open);
  }

  toggle(open) {
    // don't do anything if the open state doesn't change
    if (open === this.open) {
      return;
    }

    // update the internal state
    this.open = open;

    // handle DOM updates
    this.buttonEl.setAttribute('aria-expanded', `${open}`);
    if (open) {
      this.contentEl.removeAttribute('hidden');
    } else {
      this.contentEl.setAttribute('hidden', '');
    }
  }

  // Add public open and close methods for convenience
  open() {
    this.toggle(true);
  }

  close() {
    this.toggle(false);
  }
}

// init accordions
const accordions = document.querySelectorAll('.accordion h3');
accordions.forEach((accordionEl) => {
  new Accordion(accordionEl);
});


checkWidth();
window.onresize = checkWidth;

window.addEventListener("load", footerReveal);
window.onresize = footerReveal;
